import React, { useState, useEffect, useContext } from "react";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import "./../styles/home.css";
import Carousel from "react-bootstrap/Carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faStarHalfStroke } from "@fortawesome/free-solid-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Collapse from "react-bootstrap/Collapse";
import InputGroup from "react-bootstrap/InputGroup";
import Nav_bar_area from "./NavBar";
import { useNavigate } from "react-router-dom";

import { ContextApiContext } from "../context/ContextApi";
import { Alert } from "react-bootstrap";
import { Constant } from "../common/Constants";
import { SendRequest } from "../common/Common";
import Offcanvas from 'react-bootstrap/Offcanvas';
import {faBars} from "@fortawesome/free-solid-svg-icons";

import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

export default function Home_Page() {

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <section className="bg-cl">
        <Container fluid>
          <Row>
            <Col xs={12} md={3} className="lg_area">
              <div class="topnav_logo">
                <img src="/images/slicing/18.png" alt="" class="img-responsive" />
              </div>
            </Col>
            <Col xs={12} md={9}>
              <div class="topnav">
                <a class="active" href="/">Home</a>
                <a class="lnk" href="#showcase">Showcase</a>
                <a class="lnk" href="#contact">Contact</a>
                <a class="lnk" href="#services">Services</a>
                <a class="lnk" href="#our_products">Our Products</a>
                <a class="lnk" href="#solutions">Solutions</a>
                <a class="lnk" href="#contact">Domain & Hosting</a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sec-bg">
        <Container fluid>
          <Row>
            <Col xs={1} md={2}></Col>
            <Col xs={10} md={7}>
            <div className="nv-bt">
            <Button variant="primary" onClick={handleShow} className="nav">
              <FontAwesomeIcon icon={faBars} />
            </Button><img src="/images/slicing/18.png" alt="" class="img-responsive asd" />
            </div>
            <Offcanvas show={show} onHide={handleClose}>
              <Offcanvas.Header closeButton className="tp-nv">
                <Offcanvas.Title><img src="/images/slicing/18.png" alt="" class="img-responsive" /></Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="off">
              <div class="topnav">
              <a class="active" href="/">Home</a>
                <a href="#showcase">Showcase</a>
                <a href="#contact">Contact</a>
                <a href="#services">Services</a>
                <a href="#our_products">Our Products</a>
                <a href="#solutions">Solutions</a>
                <a href="#contact">Domain & Hosting</a>
              </div>
              </Offcanvas.Body>
            </Offcanvas>
              <div class="tophd">
                <h3>IT Business Consulting</h3>
                <h1>Our Agency Provide Best IT Solutions</h1>
                <h5>We are 100+ professional software engineers with more than 10 years of
                  experience in delivering superior products.</h5>
              </div>
            </Col>
            <Col xs={1} md={3}></Col>
          </Row>
        </Container>
      </section>
      <section id="showcase" className="third-sec">
        <Container>
          <Row>
            <Col xs={12} md={8}>
              <div className="hd-one">
                <h5>ABOUT COMPANY</h5>
                <h1><span>You Can n</span>ot Use Up Creativity.</h1>
                <p className="para">
                  {" "}
                  Does any industry face a more complex audience journey &
                  marketing sales process than B2B technology? Consider
                  the number of people who influence a sale, the length of
                  the decision-making cycle, the competing interests of the
                  people who purchase, implement, manage, and use the
                  technology. It’s a lot meaningful content here.
                </p>
              </div>
              <div className="hd-two">
                <img src="/images/slicing/16.png" alt="" class="img-responsive" />
                <h4>Experience
                  <span>Our great team of more than 1400 software experts.</span>
                </h4>
              </div>
              <div className="hd-two">
                <img src="/images/slicing/17.png" alt="" class="img-responsive" />
                <h4>Quick Support
                  <span>We’ll help you test bold new ideas while sharing your.</span>
                </h4>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="jeni">
                <img src="/images/slicing/15.png" alt="" class="img-responsive" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="services"className="forth-sec">
        <Container>
          <Row>
            <Col xs={12} md={6}>
              <div className="bx-comm">
                <h1>100 +</h1>
                <h2>Projects Completed</h2>
                <h6>In our duration we have completed over 40+ projects</h6>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="bx-comm">
                <h1>2k</h1>
                <h2>Happy Clients</h2>
                <h6>We have worked with the 2k+ happy & satisfied customers</h6>
              </div>
            </Col>
            <Col xs={12} md={12}>
              <div className="bx-hd">
                <h5>Who We Are</h5>
                <h1>We deal with the aspects of professional IT Services</h1>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img src="/images/slicing/12.png" alt="" class="img-responsive" />
                <h2>IT Solutions</h2>
                <h6>Our team handles every aspect of your IT related requirements</h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img src="/images/slicing/13.png" alt="" class="img-responsive" />
                <h2>Security System</h2>
                <h6>We respect your privacy, let
                  our team handles your
                  project with utmost
                  confidentiality.</h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img src="/images/slicing/14.png" alt="" class="img-responsive" />
                <h2>Web Development</h2>
                <h6>By using latest frameworks
                  and tools we design and
                  develop the website.</h6>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="bx">
                <img src="/images/slicing/11.png" alt="" class="img-responsive" />
                <h2>Database Security</h2>
                <h6>Customer databases are
                  protected and monitored by
                  establishing backup and
                  recovery procedures.</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="our_products" className="fivth-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="fv-hd">
                <h1>We Have Completed 100+ Projects Successfully</h1>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-bx">
                <img src="/images/slicing/10.png" alt="" class="img-responsive" />
                <h1>107</h1>
                <h5>Completed Project</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b1">
                <img src="/images/slicing/9.png" alt="" class="img-responsive" />
                <h1>2k</h1>
                <h5>Happy Clients</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b2">
                <img src="/images/slicing/8.png" alt="" class="img-responsive" />
                <h1>71</h1>
                <h5>Multi Service</h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="fv-b3">
                <img src="/images/slicing/7.png" alt="" class="img-responsive" />
                <h1>19</h1>
                <h5>Winning Awards</h5>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="solutions" className="six-sec">
        <Container>
          <Row>
            <Col xs={12} md={4}>
              <div className="sx-tec">
                <h4>TECHNOLOGY INDEX</h4>
                <h1>We Deliver Solutions
                  with the Goal of
                  Trusting
                  Workships</h1>
              </div>
            </Col>
            <Col xs={12} md={2}></Col>
            <Col xs={12} md={2}>
              <div className="ap-pic">
                <img src="/images/slicing/3.png" alt="" class="img-responsive" />
                <h6>IOT</h6>
              </div>
              <div className="ap-pics">
                <img src="/images/slicing/4.png" alt="" class="img-responsive" />
                <h6>WEB</h6>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="ap-imgg">
                <img src="/images/slicing/2.png" alt="" class="img-responsive" />
                <h6>IOS</h6>
              </div>
              <div className="ap-img">
                <img src="/images/slicing/5.png" alt="" class="img-responsive" />
                <h6>WEARALABLES</h6>
              </div>
            </Col>
            <Col xs={12} md={2}>
              <div className="ap-imge">
                <img src="/images/slicing/1.png" alt="" class="img-responsive" />
                <h6>ANDROID</h6>
              </div>
              <div className="ap-imges">
                <img src="/images/slicing/6.png" alt="" class="img-responsive" />
                <h6>REMOTE</h6>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="contact" className="contact-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="cont">
                <h1>CONTACT US</h1>
                <h5>We’re happy to answer any questions you have or provide you with an estimate.</h5>
                <h6>just send a message in the form below with any questions you may have.</h6>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Control type="name" placeholder="First Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Control type="phone" placeholder="Phone No" />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} md={6}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Control type="name" placeholder="Last Name" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
                  <Form.Control type="email" placeholder="Email" />
                </Form.Group>
              </Form>
            </Col>
            <Col xs={12} md={12}>
              <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea3">
                  <Form.Label className="comm-box">Comment or Message</Form.Label>
                  <Form.Control as="textarea" rows={10} />
                </Form.Group>
                <Button variant="primary" className="cont-bt">SEND MESSAGE</Button>{' '}
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="footer-sec">
        <Container fluid>
          <Row>
            <Col md={1}></Col>
            <Col xs={12} md={3}>
              <div className="ft-txt">
                <h4>JENISOFTTECHNOLOGIES</h4>
                <h6>We are 100+ professional software engineers
                  with more than 10 years of experience in
                  delivering superior products.</h6>
                <div className="ft-text">
                  <h4>CONTACT US</h4>
                  <h6>Call: +92 21 1234567
                    Email: support@jenisofttechnologies.com
                    Address: 123, Street No. Location.</h6>
                </div>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="ft-txt">
                <h4>MENU</h4>
                <h6>Home | About | Project | Services | Contact us</h6>
                <h4>LEGAL</h4>
                <h6>Terms of Use & Privacy</h6>
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="ft-lt">
                <h4>NEWSLETTER</h4>
                <h6>Keep yourself updated about the Changes
                  jeni soft technologies. Get Subscribed!</h6>
                <InputGroup className="mb-1">
                  <Form.Control
                    placeholder=""
                    aria-label="Recipient's username"
                    aria-describedby="basic-addon2"
                    className="ft_bt"
                  />
                  <InputGroup.Text id="basic-addon1">SUBMIT</InputGroup.Text>
                </InputGroup>
                <img src="/images/slicing/19.png" alt="" class="img-responsive" />
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </section>
      <section className="lab-sec">
        <Container>
          <Row>
            <Col xs={12} md={12}>
              <div className="ft-l">
                <h4>Copyright © 2023 jenisofttechnologies.com | All Rights Reserved | Designed by Jeni Soft Technologies</h4>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>

  );
}